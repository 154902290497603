
import store from '../store'
import { uploadFileOss } from '@/apis/rescue';
import { dataURLtoFile, formatTime } from '@/utils/index';
// import myfont from '../assets/font/simsun.ttf'

// 初始化水印配置文件
export function initWatermarkConfigData(configPrams){
    let { configData, orderInfo, location, driverInfo, keyName } = configPrams;
    let watermarkList = configData.watermarkList;
    let driverData = orderInfo.dispatch_type == 1 && orderInfo.dispatch_sync == 1 ? driverInfo : orderInfo.driver;
    watermarkList.forEach((e)=>{
        e.forEach((w)=>{
            if(w.key == 'time'){
                let arriveTime = orderInfo.arrive_time;
				let nowTime = Math.floor(new Date().getTime() / 1000);
				let timestamp = nowTime;
                let randomNum = Math.floor(Math.random() * (20 - 5 + 1)) + 5;
				if((orderInfo.customer_id == 164 || orderInfo.customer_id == 75) && orderInfo.status == 24 && arriveTime){
					// 到达救援地  中保平安 中轩平安
					let orderArriveInfo = localStorage.getItem('orderArriveInfo');
                    orderArriveInfo = orderArriveInfo ? JSON.parse(orderArriveInfo) : '';
					if(orderArriveInfo && orderArriveInfo.orderId == orderInfo.id){
						if((orderArriveInfo.nowTime - arriveTime) > 300){
							timestamp = Number(orderArriveInfo.arriveTime) + randomNum
						}
						orderArriveInfo.arriveTime = timestamp
					}else{
						if((nowTime - arriveTime) > 300){
							timestamp = Number(arriveTime) + randomNum
						}
						orderArriveInfo = {
							orderId: orderInfo.id,
							nowTime: nowTime,
							arriveTime: timestamp
						}
					}
					localStorage.setItem('orderArriveInfo', JSON.stringify(orderArriveInfo));
				}

                if((orderInfo.customer_id == 163 || orderInfo.customer_id == 73) && orderInfo.is_subscribe != 2 && (orderInfo.status == 2 || orderInfo.status == 22 || orderInfo.status == 24)){
					// 中轩平安 已接单到达效验 到达救援地时间 - 订单创建时间 > 25min 上传照片水印时间取 订单创建时间 + 25min + 随机时间
					let createTime = orderInfo.create_time;		//订单创建时间
					let assessmentTime = '';					//用于计算的时间
					let ageing = '';					//时效
					if(orderInfo.status == 2 || orderInfo.status == 22){
						assessmentTime = nowTime;
					}else if(orderInfo.status == 24){
						assessmentTime = arriveTime;
					}
					ageing = Math.ceil((assessmentTime - createTime) / 60)
					let orderArriveInfo = localStorage.getItem('orderArriveInfo');
                    orderArriveInfo = orderArriveInfo ? JSON.parse(orderArriveInfo) : '';
					if(orderArriveInfo && orderArriveInfo.orderId == orderInfo.id){
						if(ageing > 25 || (arriveTime && (orderArriveInfo.nowTime - arriveTime) > 300)){
							timestamp = Number(orderArriveInfo.arriveTime) + randomNum
						}
						orderArriveInfo.arriveTime = timestamp
					}else{
						orderArriveInfo = {
							orderId: orderInfo.id,
							nowTime: nowTime,
							arriveTime: ''
						}
						// 时效大于25min
						if(ageing > 25){
							timestamp = Number(createTime) + 1500 + randomNum;
							orderArriveInfo.arriveTime = timestamp;
						}else if(arriveTime){
                            if((nowTime - arriveTime) > 300){
    							timestamp = Number(arriveTime) + randomNum;
                            }
							orderArriveInfo.arriveTime = timestamp;
						}else{
							orderArriveInfo = ''
						}
					}
					localStorage.setItem('orderArriveInfo', JSON.stringify(orderArriveInfo));
				}
                w.text = formatTime(timestamp, 'Y/M/D h:m')
            }else if(w.key == 'name'){
                w.text = driverData ? driverData.nick : ''
            }else if(w.key == 'phone'){
                w.text = driverData ? driverData.mobile : ''
            }else if(w.key == 'imgkey'){
                w.text = keyName
            }else if(w.key == 'address'){
                w.text = location.address
            }else if(w.key == 'lnglat'){
                w.text = `lng：${location.lng}  lat：${location.lat}`
            }
        })
    })
    return configData
}
export function generateCanvasImg(data){
    return new Promise((resolve, reject) => {

        //创建一个FontFace对象，参数是字体名称和字体位置
        // const myFont = new FontFace('myFont', `url(${myfont})`)
        // myFont.load().then(font => {
        //    document.fonts.add(font) 
        // }).then(() => {

        let { img, w, h, configData } = data;
        // console.log(configData, 'configData2')
        let watermarkList = JSON.parse(JSON.stringify(configData.watermarkList));
        let fillRect = 0;           // 透明背景y轴定位
        let canvas = document.createElement('canvas')
        let cxt = canvas.getContext('2d')
        if(w > h){
            h = 1080 * (h / w);
            w = 1080;
        }else{
            w = 1680 * (w / h);
            h = 1680
        }
        canvas.width = w
        canvas.height = h
        console.log(h, w)
        // 图片加载完成，才可处理
        // 画图(这里画布与图片等宽高)
        cxt.drawImage(img, 0, 0, w, h)
        // 设置字体大小
        let fontSize = 12;
        if(w > h){
            fontSize = Math.ceil(h / 25);
        }else{
            fontSize = Math.ceil(w / 30);
        }
        cxt.font = `${fontSize}px SimSun`
        // 更改字号后，必须重置对齐方式，否则居中麻烦。设置文本的垂直对齐方式
        
        let position = configData.position;
        let textBaseline = 'top', textAlign = 'left', left = 0, top = 0;
        switch(position){
            case 'topLeft':
                textBaseline = 'top';
                textAlign = 'left';
                left = 10;
                top = 10;
                break
            case 'rightTop':
                textBaseline = 'top';
                textAlign = 'right';
                left = w - 10;
                top = 10;
                break
            case 'leftBottom':
                textBaseline = 'bottom';
                textAlign = 'left';
                left = 10;
                top = h - 10;
                break
            case 'rightBottom':
                textBaseline = 'bottom';
                textAlign = 'right';
                left = w - 10;
                top = h - 10;
                break
        }
        
        cxt.textBaseline = textBaseline
        cxt.textAlign = textAlign

        if(position == 'leftBottom' || position == 'rightBottom'){
            watermarkList = watermarkList.reverse()
            // 水印在下方时 透明背景y轴也在下方
            fillRect = h-(fontSize+10)*watermarkList.length;
        }

        cxt.globalAlpha = .5;
        cxt.fillStyle = '#e4e4e4';
        cxt.fillRect(0, fillRect, w, (fontSize+10)*watermarkList.length)

        // 文字颜色
        cxt.globalAlpha = 1;
        cxt.fillStyle = configData.textColor

        for(let i=0; i<watermarkList.length; i++){
            let watermarkLi = watermarkList[i];
            let text = '';
            for(let j=0; j<watermarkLi.length; j++){
                text += watermarkLi[j].text +' ';
            };
            let topNum = 0;
            if(position == 'topLeft' || position == 'rightTop'){
                topNum = top + (fontSize+5)*i+1
            }else{
                topNum = top - (fontSize+5)*i+1
            }
            cxt.fillText(text, left, topNum)
        }
        const imgBase64 = canvas.toDataURL('image/jpeg', 0.92);
        let imgFile = dataURLtoFile(imgBase64, 'image.jpeg')
        putOSS(imgFile).then((url)=>{
            resolve(url)
        }).catch(()=>{
            reject('生成水印图片失败')
        })
        
    // })
    })
}

export function putOSS(file) {
    return new Promise((resolve, reject) => {
        let ossInfo = JSON.parse(localStorage.getItem('ossInfo'));
        let nowTime = Date.parse(new Date()) / 1000;
        if (ossInfo && (ossInfo.expire + 3600) > nowTime){
            uploadOSS(file, ossInfo).then((res) => {
                resolve(res)
            }).catch(() => {
                reject();
            })
        }else{
            store.dispatch('getOSSInfo').then((e)=>{
                console.log(e, 2)
                uploadOSS(file, e).then((res) => {
                    resolve(res)
                }).catch(() => {
                    reject();
                })
            })
        }
    })
}
function uploadOSS(file, ossInfo){
    return new Promise((resolve, reject) => {
        let uploadUrl = ossInfo.host;
        let fileName = file.type.split('/')[1];
        let keyInfo = ossInfo.dir + '/h5_' + randomString(32) + '.' + fileName;
        let formData = new FormData();
        formData.append('Filename', 'upload');
        formData.append('name', 'upload');
        formData.append('key', keyInfo);
        formData.append('policy', ossInfo.policy);
        formData.append('OSSAccessKeyId', ossInfo.accessid);
        formData.append('success_action_status', 200);
        formData.append('signature', ossInfo.signature);
        formData.append('file', file);
        uploadFileOss(uploadUrl, formData).then((res) => {
            resolve(uploadUrl + '/' + keyInfo);
        }).catch((err) => {
            console.log(err, '阿里云上传错误')
            reject(err)
        })
    })
}
function randomString(len) {
    len = len || 32;
    var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    var maxPos = $chars.length;
    var pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}
function parseTime() {
    let time = Date.parse(new Date());
    console.log(time)
    let date = new Date(time);
    let y = date.getFullYear();
    let m = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    let time_str = y + '-' + m + '-' + d;
    return time_str
}
